import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  constructor(public router: Router) { }

  ngOnInit(): void {
  }
  redairect(){
    window.open("https://drome.co.in", "_blank");
   }
   LoginRedairect(){
    this.router.navigate(['/login']);
   } 
   SignupRedairect(){
    this.router.navigate(['/auth/signup/memberegister']);
   }
}
